<template>
  <base-section id="about-reporting">

    <base-section-heading
        color="grey lighten-2"
        :title="$t('section.reporting_headline')"
    >
      {{ $t('features_reporting_text') }}
    </base-section-heading>

    <div class="justify-center">
      <base-image :src="require(`@/assets/evaluate_masked.png`)" contain max-width="33%" class="mx-auto mb-8"/>
    </div>

    <v-container>
      <v-row>
        <v-col
            v-for="card in cards[$i18n.locale]"
            :key="card.title"
            cols="12"
            md="4"
        >
          <base-info-card v-bind="card"/>
        </v-col>
      </v-row>
    </v-container>

  </base-section>

</template>

<script>
import BaseImage from '@/components/base/Img';

export default {
  name: 'SectionAboutReporting',
  components: {BaseImage},
  data: () => ({
    cards: {
      en: [
        {
          title: 'Gain control',
          subtitle: 'Performance insights',
          text: 'Data is knowledge and gives you the opportunity to evaluate your sustainability performance so that you know where you need to improve.',
          callout: '01',
        },
        {
          title: 'Collect once',
          subtitle: 'Data collection',
          text: 'Save time by collecting your data once and let Evaluate process the data to each and all – current and future – frameworks that your organization needs to report to. ',
          callout: '02',
        },
        {
          title: 'Cover your entire value chain',
          subtitle: 'Scope 1, 2 and 3',
          text: 'Evaluate supports collecting, compiling and analyzing data, such as CO2 emissions, in scope 1-3, in order for you to gain insight of your climate footprint in the entire value chain.',
          callout: '03',
        },
      ],
      sv: [
        {
          title: 'Ta kontoll',
          subtitle: 'Insikter',
          text: 'Data är kunskap och ger dig möjlighet att utvärdera ditt företags  hållbarhetsprestation så att du vet var förbättringar behövs bäst.',
          callout: '01',
        },
        {
          title: 'Samla in en gång',
          subtitle: 'Datainsamling',
          text: 'Spara tid genom att samla in din data en gång och låt Evaluate bearbeta data till alla – nuvarande och framtida – ramverk som din organisation behöver rapportera till.',
          callout: '02',
        },
        {
          title: 'Täck hela värdekedjan',
          subtitle: 'Scope 1, 2 och 3',
          text: 'Evaluate stödjer att samla in, sammanställa och analysera data såsom CO2-utsläpp i scope 1-3 för att du ska få insikt om ditt klimatavtryck i hela värdekedjan.',
          callout: '03',
        },
      ],
    },
  }),
}
</script>
